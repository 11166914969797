import { Box, Button, Container, Typography } from '@mui/material'
import './style.css'
import { ButtonAnimated } from '../../../components/ButtonAnimated'

export function Matchmaker() {
    return (
        <Box className="newSection">
            <Container maxWidth="lg" className="newSectionContainer">
                <Typography variant="h3" component="h2" gutterBottom className="sectionTitle">
                    Encontre seu lar perfeito
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom className="sectionSubtitle">
                    Conectamos você aos melhores negócios imobiliários em Balneário Camboriú e Joinville
                </Typography>
                <Box className="sectionButtons">
                    <Button
                        variant="contained"
                        color="primary"
                        className='contact'
                        onClick={() => window.open('https://wa.me/+5547991683239?text=Olá! Gostaria de mais informações.', '_blank')}
                    >
                        Contato
                    </Button>
                    <ButtonAnimated>
                        Saiba Mais
                    </ButtonAnimated>
                </Box>
            </Container>
        </Box >
    )
}