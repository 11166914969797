import { Box, Button, Container, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import './style.css';

export function Header() {
    // Variants para as animações do texto e botão
    const textVariants = {
        hidden: { opacity: 0, y: 80 },
        visible: { opacity: 1, y: 0, transition: { type: 'spring', stiffness: 100 } }
    };

    const buttonVariants = {
        hidden: { opacity: 0, y: 80 },
        visible: { opacity: 1, y: 0, transition: { type: 'spring', stiffness: 100 } }
    };

    return (
        <Box className="background">
            <Container maxWidth="lg" className="container">
                <Box className={`box boxWidth-xs textAlign-xs`} sx={{ px: 0 }}>
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={textVariants}
                    >
                        <Typography variant="h2" component="h1" gutterBottom className={`title titleSize-xs`}>
                            Bem-vindo à <span className="highlight">Vext Imob</span> - Encontre o Lar dos Seus <span className="emphasis">Sonhos!</span>
                        </Typography>
                        <Typography variant="h5" component="h2" gutterBottom className={`subtitle subtitleSize-xs`}>
                            Descubra as melhores opções de<br /> imóveis na região com a Vext Imob!
                        </Typography>
                    </motion.div>
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={buttonVariants}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className="actionButton"
                            onClick={() => window.open('https://wa.me/+5547991683239?text=Olá! Gostaria de mais informações.', '_blank')}
                        >
                            DESCUBRA AGORA
                        </Button>
                    </motion.div>
                </Box>
            </Container>
        </Box>
    );
}
