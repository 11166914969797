import React from "react";
import Slider from "react-slick";
import { Box, Container, Typography } from "@mui/material";
import './style.css';

const Carousel = ({title, subTitle}: any) => {

  const imageNames = [
    'no-image.png',
    'no-image.png',
    'no-image.png',
    'no-image.png',
    'no-image.png',
    'no-image.png',
    'no-image.png',
  ];

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: imageNames.length === 1 ? 1 : 2.5,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: imageNames.length === 1 ? 1 : 2.5,
          slidesToScroll: 1,
          infinite: true,
          dots: true // Mantém os dots ativos em telas com largura até 1024px
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false // Oculta os dots para dispositivos com tela menor que 600px
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          dots: false // Oculta os dots para dispositivos com tela menor que 480px
        }
      }
    ]
  };


  // Caminho base para as imagens dentro do diretório public
  const basePath = process.env.PUBLIC_URL + '/assets/images/properties/';

  return (
    <Container maxWidth="lg" sx={{marginBottom: '60px'}}>
      <Typography variant="h3" component="h2" gutterBottom sx={{ color: "#f5f5f5", mt: 15 }}>
      {title}
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom sx={{ color: "#f5f5f5", fontSize: 18, mt: 4, mb: 10 }}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </Typography>
      <Slider {...settings}>
        {imageNames.map((name, index) => (
          <Box key={index} className="carouselImageContainer">
            <img src={`${basePath}${name}`} alt={`Imagem ${index + 1}`} />
          </Box>
        ))}
      </Slider>
    </Container>
  );
};

export default Carousel;
