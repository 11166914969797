import React from 'react';
import './style.css';

const CompanyLocations = () => {
  return (
    <div>
      <h2>Localização da Empresa</h2>
      <h3>Balneário Piçarras</h3>
      <div className="map-container">
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3577.13210310029!2d-48.852617023830454!3d-26.2898171674676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94deb100423766c9%3A0xceaf993f35957ad!2sVEXT%20IMOB!5e0!3m2!1spt-BR!2sbr!4v1711137522969!5m2!1spt-BR!2sbr"
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '460px', border: 0 }}
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade">
        </iframe>
      </div>
    </div>
  );
};

export default CompanyLocations;
