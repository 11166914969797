import { Box, Button, Container, Typography, TextField, Checkbox, FormControlLabel, InputLabel, Snackbar, Alert } from '@mui/material';
import { useRef, useState } from 'react';
import './style.css';

export function Contact() {
    const [checked, setChecked] = useState('');
    const [formData, setFormData] = useState({
        nome: '',
        telefone: '',
        email: '',
    });
    const nomeRef: any = useRef(null);
    const telefoneRef: any = useRef(null);
    const emailRef: any = useRef(null);

    const [telefone, setTelefone] = useState('');


    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleCheckboxChange = (event: any) => {
        setChecked(event.target.name);
    };

    const handleInputChange = (event: { target: { name: any; value: any; }; }) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const validateForm = () => {
        if (!formData.nome) {
            setSnackbarMessage("Por favor, preencha o campo nome.");
            setOpenSnackbar(true);
            nomeRef.current.focus();
            return false;
        } else if (!formData.telefone) {
            setSnackbarMessage("Por favor, preencha o campo telefone.");
            setOpenSnackbar(true);
            telefoneRef.current.focus();
            return false;
        } else if (!formData.email) {
            setSnackbarMessage("Por favor, preencha o campo email.");
            setOpenSnackbar(true);
            emailRef.current.focus();
            return false;
        } else if (!checked) {
            setSnackbarMessage("Por favor, selecione um escritório.");
            setOpenSnackbar(true);
            return false;
        }
        return true;
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (validateForm()) {
            console.log('Formulário enviado:', formData);
        }
    };

    const handleTelefoneChange = (event: { target: { value: string; }; }) => {
        const originalValue = event.target.value.replace(/\D/g, '');
        let maskedValue = '';
    
        if (originalValue.length > 10) {
            maskedValue = originalValue.replace(/^(\d{2})(\d{5})(\d{1,4}).*$/, '($1) $2-$3').substr(0, 15);
        } else if (originalValue.length > 6) {
            maskedValue = originalValue.replace(/^(\d{2})(\d{4})(\d{0,4}).*$/, '($1) $2-$3');
        } else if (originalValue.length > 2) {
            maskedValue = originalValue.replace(/^(\d{2})(\d{0,4}).*$/, '($1) $2');
        } else if (originalValue.length > 0) {
            maskedValue = originalValue.replace(/^(\d{0,2}).*$/, '($1');
        } else {
            maskedValue = '';
        }
    
        // Atualiza o estado local do telefone
        setTelefone(maskedValue);
    
        // Atualiza o estado do formData com o novo valor do telefone
        setFormData(prevFormData => ({
            ...prevFormData,
            telefone: maskedValue
        }));
    };
    
    return (
        <Box sx={{ background: 'black', color: '#6cde04', p: 4 }}>
            <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom sx={{ color: '#6CDE04', fontSize: '48px', fontWeight: '700', lineHeight: '57.6px' }}>
                    Entre em contato
                </Typography>
                <Typography sx={{ color: 'white', mb: 2 }}>
                    Preencha o formulário abaixo para entrar em contato conosco.
                </Typography>
                <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <InputLabel sx={{ color: 'white', textAlign: 'left', mt: 2, display: 'block' }}>Nome</InputLabel>
                    <TextField
                        inputRef={nomeRef}
                        name="nome"
                        value={formData.nome}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        variant="filled"
                        sx={{
                            backgroundColor: 'white',
                            borderRadius: '0',
                            '& .MuiInput-underline:after': {
                                borderBottomColor: '#6cde04',
                            },
                            '& .MuiFilledInput-underline:after': {
                                borderBottomColor: '#6cde04',
                            },
                            '& .MuiFilledInput-underline:before': {
                                borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Change default underline color
                            },
                            '&:hover .MuiFilledInput-underline:before': {
                                borderBottomColor: 'rgba(0, 0, 0, 0.87)', // Change underline color on hover
                            }
                        }}
                    />
                    <InputLabel sx={{ color: 'white', textAlign: 'left', mt: 2, display: 'block' }}>Telefone</InputLabel>
                    <TextField
                        inputRef={telefoneRef}
                        name="telefone"
                        value={telefone}
                        onChange={handleTelefoneChange}
                        fullWidth
                        margin="normal"
                        variant="filled"
                        sx={{
                            backgroundColor: 'white',
                            borderRadius: '0',
                            '& .MuiInput-underline:after': {
                                borderBottomColor: '#6cde04',
                            },
                            '& .MuiFilledInput-underline:after': {
                                borderBottomColor: '#6cde04',
                            },
                            '& .MuiFilledInput-underline:before': {
                                borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Change default underline color
                            },
                            '&:hover .MuiFilledInput-underline:before': {
                                borderBottomColor: 'rgba(0, 0, 0, 0.87)', // Change underline color on hover
                            }
                        }} />
                    <InputLabel sx={{ color: 'white', textAlign: 'left', mt: 2, display: 'block' }}>Email</InputLabel>
                    <TextField
                        inputRef={emailRef}
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        variant="filled"
                        sx={{
                            backgroundColor: 'white',
                            borderRadius: '0',
                            '& .MuiInput-underline:after': {
                                borderBottomColor: '#6cde04',
                            },
                            '& .MuiFilledInput-underline:after': {
                                borderBottomColor: '#6cde04',
                            },
                            '& .MuiFilledInput-underline:before': {
                                borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Change default underline color
                            },
                            '&:hover .MuiFilledInput-underline:before': {
                                borderBottomColor: 'rgba(0, 0, 0, 0.87)', // Change underline color on hover
                            }
                        }} />
                    <Box sx={{ display: 'grid', justifyContent: 'start', mb: 2 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked === 'joinville'}
                                    onChange={handleCheckboxChange}
                                    name="joinville"
                                    sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }}
                                />
                            }
                            label="Joinville"
                            sx={{ color: 'white' }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked === 'balneario'}
                                    onChange={handleCheckboxChange}
                                    name="balneario"
                                    sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }}
                                />
                            }
                            label="Balneário Piçarras"
                            sx={{ color: 'white' }}
                        />
                        <Button type="submit" className='contact' sx={{ mt: 2, width: { xs: '100%', md: '99px' } }}>
                            Enviar
                        </Button>
                    </Box>
                </Box>
            </Container>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleCloseSnackbar} severity="warning" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}
