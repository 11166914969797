import React from "react";
import './style.css';
import NavBar from "../../components/NavBar";
import { ThemeProvider } from "@mui/material";
import monochrome from "../../Themes/Monochrome";
import { Header } from "../../sections/Home/Header";
import { Matchmaker } from "../../sections/Home/Matchmaker";
import Testimonials from "../../sections/Home/Testimonials";
import { Contact } from "../../sections/Home/Contact";
import CompanyLocations from "../../sections/Home/CompanyLocations";
import Footer from "../../sections/Home/Footer";
import Carousel from "../../sections/Home/Carousel";

const Home = () => {
  return (
    <ThemeProvider theme={monochrome}>
      <NavBar />
      <Header />
      <Matchmaker />
      <Carousel title="Destaques" subTitle=""/>
      <Carousel title="Novidades" subTitle="" />
      <Testimonials />
      <Contact/>
      <CompanyLocations />
      <Footer />
    </ThemeProvider>
  );
};

export default Home;
